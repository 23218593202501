import {
  FaFacebook,
  FaLinkedin,
  FaProductHunt,
  FaRoute,
  FaRupeeSign,
  FaStore,
  FaTwitter,
} from 'react-icons/fa';
import './home.scss';
import { AiOutlineSchedule, AiTwotoneSchedule } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { IoLogoInstagram } from 'react-icons/io';

export default function Home(props: any) {
  return (
    <>
      <section className="home">
        <div className="tagline-n-banner common-container">
          <div className="cnt-of-tagline-n-small-description">
            <h1 className="tagline">Make your business smart.</h1>
            <p className="small-description">
              Expand your business and increase sales by cutting costs. Capture
              more customers than ever before with a cost-effective strategy for
              higher conversions.
            </p>
          </div>

          <img
            src="/images/modulus-sell-banner.png"
            className="sell-banner"
            alt="banner"
          />
        </div>

        {/* why we are best and what does we provide */}

        <article className="services" id="services">
          <div className="container">
            <h3 className="text-center heading">What is Modulus Sell?</h3>
            <p className="text-center large-description">
              Modulus Sell revolutionizes sales management with easy territory
              control and route planning. Businesses can register, define areas,
              and streamline daily or alternative routes effortlessly. This
              dynamic platform eliminates the hassle of manual order-taking,
              keeping your operations nimble and competitive. Elevate your
              selling strategy with Modulus Sell—where efficiency meets
              innovation.
            </p>
            <article className="flex justify-between wrap">
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <div className="stats">
                  <FaRoute />
                </div>
                <h4>About Routes</h4>
                <p>
                  Enter your route details to plan your product journey and
                  kickstart your path to success!
                </p>
              </div>
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <div className="stats">
                  <AiOutlineSchedule
                    style={{
                      fontSize: '33px',
                    }}
                  />
                </div>
                <h4>About Schedules</h4>
                <p>
                  Effortlessly manage your route schedules by filling in the
                  details for each journey. Take control of your timelines and
                  optimize your process effortlessly.
                </p>
              </div>
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <div className="stats">
                  <FaRupeeSign />
                </div>
                <h4>About Reducing Costs</h4>
                <p>
                  Say goodbye to the hassle of taking orders manually and let
                  Modulus Sell streamline your process, reducing both time and
                  expenses.
                </p>
              </div>
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <div className="stats">
                  <FaProductHunt
                    style={{
                      fontSize: '33px',
                    }}
                  />
                </div>
                <h4>Add Your Products</h4>
                <p>
                  Easily add your products on Modulus Sell. Sell by piece, box,
                  or bundle, and customize offers, minimum quantities, and price
                  tiers effortlessly. Our user-friendly interface simplifies
                  showcasing and managing your products.
                </p>
              </div>
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <span className="stats">
                  <MdOutlineFavoriteBorder
                    style={{
                      fontSize: '33px',
                    }}
                  />
                </span>
                <h4>Managing Orders</h4>
                <p>
                  Empower yourself in order management on Modulus Sell. Accept
                  or decline orders with the ability to provide reasons for
                  declines. Once accepted, assign a route for seamless product
                  delivery, ensuring accountability on both the buyer and seller
                  sides.
                </p>
              </div>
              <div className="text-center flex-30 sm-flex-48 sm-flex-100">
                <span className="stats">
                  <FaStore
                    style={{
                      fontSize: '33px',
                    }}
                  />
                </span>
                <h4>
                  Utilize your store's public profile to reach and inform
                  potential buyers.
                </h4>
                <p>
                  Maximize your store's public profile to connect with potential
                  buyers. Share your seller profile on social media platforms to
                  boost sales and engagement. Showcase everything about your
                  store, including the brands and products you offer, enhancing
                  professionalism and making a lasting impression on potential
                  buyers.
                </p>
              </div>
            </article>
          </div>
        </article>
      </section>
      {/* footer */}
      <section className="padding background footer-links">
        <article className="container flex wrap justify-around sm-column footer">
          <div className="flexor flex-23 sm-flex-50 ">
            <h6>|17| Modulus Seventeen</h6>
            <address>Choori Kasaragod, Kerala 671124 India</address>
            <p>
              <span>Phone:</span> +91 7356001171
            </p>
            <p>
              <span>Email:</span> support@modulussell.com
            </p>
          </div>
          <nav className="Useful-links flex-20 sm-flex-50">
            <h4>Useful Links </h4>
            <ul>
              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="/store/create"> Add Store</Link>
              </li>
              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="https://buy.modulusseventeen.com/">|Buy|</Link>
              </li>

              <li>
                {' '}
                <span>
                  <i className="fas fa-angle-right"></i>
                </span>{' '}
                <Link to="/privacy"> Privacy Policy</Link>
              </li>
            </ul>
          </nav>
        </article>

        <footer id="contact" className="footer">
          <div className="container footer-social-links sm-center flex justify-between item-center">
            <div>
              <small>
                &copy; Copyright <span>|17|</span>. All Rights Reserved
              </small>{' '}
              <br />
            </div>
            <nav className="footer-nav">
              <ul className="flex">
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to="https://twitter.com/moduluseventeen">
                    <FaTwitter
                      style={{
                        display: 'block',
                      }}
                    />
                  </Link>{' '}
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to="https://www.facebook.com/modulusseventeen">
                    <FaFacebook />
                  </Link>{' '}
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to="https://www.instagram.com/modulusseventeen/?hl=en">
                    <IoLogoInstagram />
                  </Link>{' '}
                </li>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to="https://www.linkedin.com/company/modulus-seventeen/about/">
                    <FaLinkedin />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="top-btn">
            <Link to="#">
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </footer>
      </section>
    </>
  );
}
